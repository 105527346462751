<template>
  <div class="container-fluid py-3">
    <!-- Call Summary -->
    <div id="display-call-summary-container-202101041318"
         v-if="!!callId"
         class="call-summary"
    >
      <call-summary
        :call-id="callId"
        :isOpenFromCallViewSummary="true"
        :wasLiveConversation="wasLiveConversation"
        @call-tags-retrieved="handleCallTagsRetrieved"
      ></call-summary>

      <!-- Confetti -->
      <transition name="fade">
        <base-confetti
          v-if="showEncouragingMessage"
          :show-confetti="showCallConfetti"
          :emoji="confettiEmoji"
          :subheading="confettiDescription"
          @close-confetti="showEncouragingMessage = false"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import CallSummary from "../call_history/CallSummary"
import BaseConfetti from "@/apps/confetti/BaseConfetti"
import CallConfettiMixin from "@/apps/confetti/CallConfettiMixin"
import { mapGetters } from "vuex"
import { CALL_MAIN_APP } from "."

export default {
  name: "CallViewSummary",
  mixins: [CallConfettiMixin],
  components: {
    CallSummary,
    BaseConfetti
  },
  data () {
    return {
      wasLiveConversation: false
    }
  },
  computed: {
    ...mapGetters({
      canShowEncouragingMessage: "auth/canShowEncouragingMessage"
    }),
    callId () {
      return this.$route.params.callId
    }
  },
  methods: {
    handleCallTagsRetrieved (callTags) {
      if (this.canShowEncouragingMessage) this.setupEncouragingCallMessage(callTags)
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      if (from.name === CALL_MAIN_APP && !vm.$route.query.editConversation) {
        vm.wasLiveConversation = true
      }
    })
  }
}
</script>

<style scoped lang="scss">
.call-summary {
  width: 100%;
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  align-self: flex-start;
}
</style>
