export const CALL_RESULT_NEGATIVE_LABEL = "call_result_negative"
export const CALL_RESULT_NEUTRAL_LABEL = "call_result_neutral"
export const CALL_RESULT_POSITIVE_LABEL = "call_result_positive"
export const COUNTERPART_NOT_REACHED_LABEL = "counterpart_not_reached"
export const COUNTERPART_REACHED_LABEL = "counterpart_reached"
export const CALL_RESULT_NEGATIVE_COUNT = "call_result_negative_count"
export const OTHER_CALL_TAGS_INTERVAL = "other_call_tags_interval"
export const OTHER_CALL_TAGS_COUNT = "other_call_tags_count"
export const CALL_CONFETTI_TIMEOUT = 5500
export const CALL_RESULT_NEGATIVE_CONFETTI_INTERVAL = 3

export const randNum = (num1, num2) => {
  // This generates a random number between two numbers
  return Math.floor(Math.random() * (num2 - num1 + 1)) + num1
}

export const callConfettiLabels = [
  CALL_RESULT_NEGATIVE_LABEL,
  CALL_RESULT_POSITIVE_LABEL,
  CALL_RESULT_NEUTRAL_LABEL,
  COUNTERPART_NOT_REACHED_LABEL,
  COUNTERPART_REACHED_LABEL
]
