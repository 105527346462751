import {
  CALL_RESULT_NEGATIVE_LABEL,
  CALL_RESULT_NEUTRAL_LABEL,
  CALL_RESULT_POSITIVE_LABEL,
  COUNTERPART_NOT_REACHED_LABEL,
  COUNTERPART_REACHED_LABEL,
  CALL_RESULT_NEGATIVE_COUNT,
  OTHER_CALL_TAGS_INTERVAL,
  OTHER_CALL_TAGS_COUNT,
  CALL_CONFETTI_TIMEOUT,
  CALL_RESULT_NEGATIVE_CONFETTI_INTERVAL,
  randNum,
  callConfettiLabels
} from "./utils"

const CallConfettiMixin = {
  name: "CallConfettiMixin",
  data () {
    return {
      showCallConfetti: false,
      showEncouragingMessage: false,
      confettiEmoji: null,
      confettiDescription: null,
      staticTextDefault: {
        callResultNegative1: "Life is a carousel. It goes up and down. All you gotta do is just stay on.",
        callResultNegative2: "Don't be discouraged. Every unsuccessful call is an opportunity to learn and grow. Keep moving forward!",
        callResultNegative3: "This setback is just temporary. You're a great salesperson and you'll bounce back stronger than ever.",
        callResultNegative4: "Think of this as a stepping stone on the path to success. Keep pushing and you'll get there.",
        callResultNegative5: "Keep putting in the effort and you'll see results.",
        callResultNegative6: "Keep pushing and don't be discouraged by rejections. Every call is a step towards success.",
        callResultNegative7: "Don't worry, sometimes things don't go according to plan, but it's important to keep trying.",
        callResultNegative8: "Every 'no' brings you closer to a 'yes'. Don't give up, keep pushing and you will achieve your goals!",
        callResultNegative9: "Remember, the most successful salespeople face rejection and setbacks but they never give up. ",
        callResultNeutral1: "Well done, that was a good call - keep on going!",
        callResultNeutral2: "It's okay to have a tough call. Use this as motivation to improve and come back even stronger next time.",
        callResultNeutral3: "Keep in mind that every call is different. Don't get discouraged, and keep pushing towards your goals.",
        callResultNeutral4: "Use this opportunity to reflect and think about what you can do differently next time. Every call is a chance!",
        callResultNeutral5: "Remember, success is not just about closing a sale, it's about building relationships.",
        callResultNeutral6: "Keep a positive attitude and don't get discouraged.",
        callResultNeutral7: "Stay focused, stay motivated, and keep pushing towards your goals.",
        callResultNeutral8: "Stay focused and stay motivated. Every call is an opportunity to grow and get closer to your goal.",
        callResultPositive1: "Hooray, that was a great call, congratulations!",
        callResultPositive2: "Great job! Your hard work and dedication paid off. Keep up the amazing work!",
        callResultPositive3: "You did it! Celebrate this moment and enjoy the fruits of your labor. Well done!",
        callResultPositive4: "Fantastic work! You should be proud of this achievement. Keep crushing your goals!",
        callResultPositive5: "You're on fire! Keep the momentum going and reach for more successful calls.",
        counterpartNotReached1: "Don’t get frustrated - next time you’ll have more luck reaching your counterpart.",
        counterpartNotReached2: "Don't be discouraged by not reaching your counterpart. Keep trying and you'll get through.",
        counterpartNotReached3: "Sometimes it can be difficult to reach people, but don't let that discourage you. Keep trying!",
        counterpartNotReached4: "This is just a temporary setback. Keep trying and you'll get the chance to make your pitch.",
        counterpartNotReached5: "Keep pushing forward. You'll reach your counterpart soon enough.",
        counterpartNotReached6: "Keep trying, and don't let this setback get you down. Every call is a step towards your ultimate goal!",
        counterpartNotReached7: "The more calls you make, the more chances you have to connect with someone.",
        counterpartReached1: "Great job, keep on ringing to reach your next counterparts!",
        counterpartReached2: "Great job reaching out! Every call is a step towards your ultimate goal, keep up the good work.",
        counterpartReached3: "You're on the right track! Keep making calls and you'll connect with someone who is interested.",
        counterpartReached4: "Good work! Keep making calls and you'll find the right person. Keep pushing and you'll get there.",
        counterpartReached5: "Great job reaching your counterpart! Keep up the good work and you'll close a sale.",
        counterpartReached6: "You're on the right track! Keep making calls and reaching out to people, and you'll find success.",
        counterpartReached7: "Good work! Keep reaching out to people and you'll find the right person. Keep pushing!"
      }
    }
  },
  computed: {
    callConfettiStaticText () {
      return this.$store.getters["I18nStore/getI18n"]("CallConfettiMixin", this.staticTextDefault)
    },
    callConfettiDictionary () {
      return {
        [CALL_RESULT_NEGATIVE_LABEL]: [
          {
            emoji: "&#x1F643;",
            text: this.callConfettiStaticText.callResultNegative1
          },
          {
            emoji: "&#x1F60E;",
            text: this.callConfettiStaticText.callResultNegative2
          },
          {
            emoji: "&#x1F917;",
            text: this.callConfettiStaticText.callResultNegative3
          },
          {
            emoji: "&#x1F4AA;",
            text: this.callConfettiStaticText.callResultNegative4
          },
          {
            emoji: "&#x1F4AA;",
            text: this.callConfettiStaticText.callResultNegative5
          },
          {
            emoji: "&#x1F643;",
            text: this.callConfettiStaticText.callResultNegative6
          },
          {
            emoji: "&#x1F4AA;",
            text: this.callConfettiStaticText.callResultNegative7
          },
          {
            emoji: "&#x1F917;",
            text: this.callConfettiStaticText.callResultNegative8
          },
          {
            emoji: "&#x1F609;",
            text: this.callConfettiStaticText.callResultNegative9
          }
        ],
        [CALL_RESULT_NEUTRAL_LABEL]: [
          {
            emoji: "&#x1F60E;",
            text: this.callConfettiStaticText.callResultNeutral1
          },
          {
            emoji: "&#x1F643;",
            text: this.callConfettiStaticText.callResultNeutral2
          },
          {
            emoji: "&#x1F609;",
            text: this.callConfettiStaticText.callResultNeutral3
          },
          {
            emoji: "&#x1F913;",
            text: this.callConfettiStaticText.callResultNeutral4
          },
          {
            emoji: "&#x1F4AA;",
            text: this.callConfettiStaticText.callResultNeutral5
          },
          {
            emoji: "&#x1F643;",
            text: this.callConfettiStaticText.callResultNeutral6
          },
          {
            emoji: "&#x1F917;",
            text: this.callConfettiStaticText.callResultNeutral7
          },
          {
            emoji: "&#x1F913;",
            text: this.callConfettiStaticText.callResultNeutral8
          }
        ],
        [CALL_RESULT_POSITIVE_LABEL]: [
          {
            emoji: "&#x1F44F;",
            text: this.callConfettiStaticText.callResultPositive1
          },
          {
            emoji: "&#x1F60E;",
            text: this.callConfettiStaticText.callResultPositive2
          },
          {
            emoji: "&#x1F973;",
            text: this.callConfettiStaticText.callResultPositive3
          },
          {
            emoji: "&#x1F929;",
            text: this.callConfettiStaticText.callResultPositive4
          },
          {
            emoji: "&#x1F525;",
            text: this.callConfettiStaticText.callResultPositive5
          }
        ],
        [COUNTERPART_NOT_REACHED_LABEL]: [
          {
            emoji: "&#x1F4AA;",
            text: this.callConfettiStaticText.counterpartNotReached1
          },
          {
            emoji: "&#x1F913;",
            text: this.callConfettiStaticText.counterpartNotReached2
          },
          {
            emoji: "&#x1F917;",
            text: this.callConfettiStaticText.counterpartNotReached3
          },
          {
            emoji: "&#x1F917;",
            text: this.callConfettiStaticText.counterpartNotReached4
          },
          {
            emoji: "&#x1F4AA;",
            text: this.callConfettiStaticText.counterpartNotReached5
          },
          {
            emoji: "&#x1F4AA;",
            text: this.callConfettiStaticText.counterpartNotReached6
          },
          {
            emoji: "&#x1F919;",
            text: this.callConfettiStaticText.counterpartNotReached7
          }
        ],
        [COUNTERPART_REACHED_LABEL]: [
          {
            emoji: "&#x1F44F;",
            text: this.callConfettiStaticText.counterpartReached1
          },
          {
            emoji: "&#x1F64C;",
            text: this.callConfettiStaticText.counterpartReached2
          },
          {
            emoji: "&#x1F44F;",
            text: this.callConfettiStaticText.counterpartReached3
          },
          {
            emoji: "&#x1F919;",
            text: this.callConfettiStaticText.counterpartReached4
          },
          {
            emoji: "&#x1F44F;",
            text: this.callConfettiStaticText.counterpartReached5
          },
          {
            emoji: "&#x1F44F;",
            text: this.callConfettiStaticText.counterpartReached6
          },
          {
            emoji: "&#x1F64C;",
            text: this.callConfettiStaticText.counterpartReached7
          }
        ]
      }
    }
  },
  methods: {
    setupEncouragingCallMessage (tags) {
      const tagLabels = tags.map((tag) => { return this.transformLabelForConfetti(tag.label) })
      const confettiLabel = this.getCallConfettiLabel(tagLabels)
      if (confettiLabel) {
        const { showEncouragingMessage, shouldShowConfetti, label } = this.canShowEncouragingMessageAndConfetti(confettiLabel)
        if (showEncouragingMessage) {
          const { showConfetti, encouragingMessage } = this.getConfettiMessage({ shouldShowConfetti, label })
          this.updateConfettiValues({ showConfetti, encouragingMessage })
        }
      }
    },
    transformLabelForConfetti (label) {
      return label === "negative"
        ? CALL_RESULT_NEGATIVE_LABEL
        : label === "positive"
          ? CALL_RESULT_POSITIVE_LABEL
          : label === "neutral"
            ? CALL_RESULT_NEUTRAL_LABEL
            : label
    },
    getCallConfettiLabel (tagLabels) {
      // These labels are in order of priority. The call result is recognized
      // as more important than the counterpart reached or not reached.
      const confettiLabels = callConfettiLabels

      // This is so I can check for the labels at O(1) rather than running multiple
      // loops with an array
      const tagLabelsObject = {}
      for (const tagLabel of tagLabels) tagLabelsObject[tagLabel] = true

      for (const label of confettiLabels) if (tagLabelsObject[label]) return label

      return null
    },
    canShowEncouragingMessageAndConfetti (label) {
      let showEncouragingMessage = false
      let shouldShowConfetti = false

      if (label === CALL_RESULT_POSITIVE_LABEL) {
        showEncouragingMessage = true
        shouldShowConfetti = true
      } else if (label === CALL_RESULT_NEGATIVE_LABEL) {
        let callResultNegativeCount = localStorage.getItem(CALL_RESULT_NEGATIVE_COUNT)

        if (!(Number(callResultNegativeCount))) localStorage.setItem(CALL_RESULT_NEGATIVE_COUNT, 1)
        else {
          callResultNegativeCount = Number(callResultNegativeCount) + 1
          if (callResultNegativeCount === CALL_RESULT_NEGATIVE_CONFETTI_INTERVAL) {
            showEncouragingMessage = true
            localStorage.setItem(CALL_RESULT_NEGATIVE_COUNT, 0)
          } else {
            localStorage.setItem(CALL_RESULT_NEGATIVE_COUNT, callResultNegativeCount)
          }
        }
      } else {
        const otherCallTagsInterval = localStorage.getItem(OTHER_CALL_TAGS_INTERVAL)
        // This is to set a random number between 4 and 6 as the interval for showing confetti
        if (!Number(otherCallTagsInterval)) localStorage.setItem(OTHER_CALL_TAGS_INTERVAL, randNum(4, 6))

        let otherCallTagsCount = localStorage.getItem(OTHER_CALL_TAGS_COUNT)
        if (!Number(otherCallTagsCount)) localStorage.setItem(OTHER_CALL_TAGS_COUNT, 1)
        else {
          otherCallTagsCount = Number(otherCallTagsCount) + 1

          if (otherCallTagsCount === Number(otherCallTagsInterval)) {
            showEncouragingMessage = true
            localStorage.setItem(OTHER_CALL_TAGS_COUNT, 0)
            // This is to reset the random number
            localStorage.setItem(OTHER_CALL_TAGS_INTERVAL, randNum(4, 6))
          } else {
            localStorage.setItem(OTHER_CALL_TAGS_COUNT, otherCallTagsCount)
          }
        }
      }

      return {
        showEncouragingMessage,
        shouldShowConfetti,
        label
      }
    },
    getConfettiMessage ({ shouldShowConfetti, label }) {
      // This gets a random message from the dictionary depending on the tag on the call
      const labelOptions = this.callConfettiDictionary[label].length
      const encouragingMessage = this.callConfettiDictionary[label][randNum(0, labelOptions - 1)]
      return { showConfetti: shouldShowConfetti, encouragingMessage }
    },
    updateConfettiValues ({ showConfetti, encouragingMessage }) {
      this.showEncouragingMessage = true
      this.showCallConfetti = showConfetti
      this.confettiEmoji = encouragingMessage.emoji
      this.confettiDescription = encouragingMessage.text
      setTimeout(() => {
        this.resetConfettiValues()
      }, CALL_CONFETTI_TIMEOUT)
    },
    resetConfettiValues () {
      this.showEncouragingMessage = false
      this.showCallConfetti = false
      this.confettiEmoji = null
      this.confettiDescription = null
    }
  }
}

export default CallConfettiMixin
